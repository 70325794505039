import BouncingDots from '@exsat/common/components/BouncingDots.vue'
import Icon from '@exsat/common/components/Icon.vue'
import Skeleton from '@exsat/common/components/Skeleton.vue'

import * as configMap from '@exsat/common/config.js'
import dayjs from '@exsat/common/dayjs.js'

import Toast from '@exsat/common/toast.js'
import { displayOrdinal, displayTokenValue } from '@exsat/common/utils.js'
import { injected } from '@wagmi/connectors'
import { connect, createConfig, http, reconnect, switchChain } from '@wagmi/core'
import { APIClient } from '@wharfkit/antelope'
import { ContractKit } from '@wharfkit/contract'

import {
  Col,
  ConfigProvider,
  DatePicker,
  Flex,
  Layout,
  Row,
  Tooltip,
  Popover,
  Drawer,
  Select,
  Input
} from 'ant-design-vue'

import { orderBy } from 'lodash-es'
import { defineChain } from 'viem'
import { createApp, reactive, ref } from 'vue'

import App from './App.vue'
import router from './router.js'

const app = createApp(App)
const components = [
  Flex,
  Row,
  Col,
  Layout,
  Layout.Content,
  Layout.Footer,
  Tooltip,
  ConfigProvider,
  DatePicker.RangePicker,
  Popover,
  Drawer,
  Select,
  Input
]
const install = (app) => {
  components.forEach((component) => {
    app.component(component.name, component)
  })
}

const exsatConfig = configMap[localStorage.exsatNetwork] || configMap.mainnet
const contractKit = new ContractKit({ client: new APIClient({ url: exsatConfig.contractUrl }) })
const wagmiConfig = createConfig({
  chains: [defineChain(exsatConfig.chainConfig)],
  transports: {
    [exsatConfig.chainConfig.id]: http()
  },
  connectors: []
})
localStorage.setItem('exsatApiBase', exsatConfig.apiBase)
console.log('network', exsatConfig.apiBase)

const account = ref(localStorage.exsatAccount || '')

app.config.globalProperties.getSvg = function getSvg(name) {
  return new URL(`./assets/img/${name}.svg`, import.meta.url).href
}
app.config.globalProperties.$day = dayjs
app.config.globalProperties.$back = () => {
  const canGoBack = router.options.history.state.position > 0
  if (canGoBack) {
    return router.back()
  }
  router.push('/')
}

app.config.globalProperties.$ordinal = displayOrdinal
app.config.globalProperties.$tokenValue = displayTokenValue
app.provide('$tokenValue', displayTokenValue)

app.component('Ske', Skeleton)
app.component('BouncingDots', BouncingDots)
app.component('Icon', Icon)

app.provide('contractKit', contractKit)
app.provide('wagmiConfig', wagmiConfig)

app.provide('config', exsatConfig)
app.provide('updateConfig', (val) => (exsatConfig.value = val))

app.provide('checkConnection', checkConnection)
app.provide('toast', Toast)
/* Usage
 const toast = inject('toast');
 toast.success({title: 'xxx', context: '...support html...'})
 toast.error({title: 'xxx', context: '...support html...', duration: 5000})
 */

app.provide('account', account)
app.provide('updateAccount', (val) => (account.value = val))

app.provide('getSortedData', (array, order, cb) => {
  if (!array) {
    return []
  }
  if (typeof cb === 'function') {
    array = cb(array)
  }
  if (!order) {
    return array
  }
  const field = order.replace(/^-/, '')
  const orders = order.startsWith('-') ? 'desc' : 'asc'
  return orderBy(array, (i) => parseFloat(i[field]) ?? i[field], [orders])
})
app.provide('modal', reactive({}))

app.use(router)
app.use(install)
app.mount('#app')

checkConnection(true)

async function checkConnection(reconnectOnly) {
  console.log('Checking connection')
  if (account.value) {
    return
  }

  let r = await reconnect(wagmiConfig)
  if (r.length > 0 && r[0].accounts.length > 0) {
    checkChainID(r[0].chainId)
    account.value = r[0].accounts[0]
    watchChanges()
    return
  }
  if (!reconnectOnly) {
    try {
      r = await connect(wagmiConfig, { connector: injected() })
    } catch (e) {
      if (e.name === 'ProviderNotFoundError') {
        window.alert('Please install a supported wallet such as Metamask to connect.')
      }
      return
    }
    checkChainID(r.chainId)
    if (r.accounts.length > 0) {
      console.log('login')
      account.value = r.accounts[0]
      watchChanges()
    }
  }
}

async function checkChainID(chainid) {
  let targetChainid = wagmiConfig.chains[0].id
  if (chainid !== targetChainid) {
    window.alert('Please switch to the correct chain to continue!')
    switchChain(wagmiConfig, { chainId: targetChainid }).then(() => {
      window.location.reload()
    })
  }
}

function watchChanges() {
  // Due to package bugs, we have to use the old injected povider if available
  if (window.ethereum) {
    window.ethereum.on('chainChanged', (chainId) => {
      checkChainID(Number(chainId))
    })

    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length > 0) {
        account.value = accounts[0]
      }
    })
  }
}

window.toast = Toast
